$(function () {
  $("#burger").on("click", function () {
    $("body").toggleClass("open");
  });
  if ($("#top .main-visual-slide-list").length) {
    var size = $("html").css("font-size");
    size = parseInt(size.replace("px", "")) / 0.625;
    if (window.innerWidth < 61.25 * size) {
      var height =
        window.innerHeight -
        $(".header-container").outerHeight() -
        $(".header-fixed").outerHeight() -
        $("#top .main-visual-linklist").outerHeight();
      $("#top .main-visual-slide").outerHeight(height);
      $("#top .main-visual-slide-list li img").outerHeight(height + 125);
    }
    $("#top .main-visual-slide-list").slick({
      arrows: false,
      autoplay: false,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      pauseOnFocus: true,
      fade: true,
      speed: 4000,
    });
    setTimeout(function () {
      $("#top .main-visual-slide-list").slick("slickPlay");
    }, 4000);
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      var n = scroll / 3;
      $("#top .main-visual-slide-list").css(
        "transform",
        "translate3d(0," + n + "px,0)"
      );
    });
  }
  if ($(".news-tag").length) {
    $("#top .news-tag-list-item").removeClass("on").eq(0).addClass("on");
    $(".news-list .news-linklist").hide().eq(0).show();
    $("#top .news-tag-list-item a").on("click", function () {
      var index = $(this).parents(".news-tag-list-item").index();
      $("#top .news-tag-list-item").removeClass("on").eq(index).addClass("on");
      $(".news-list .news-linklist").hide().eq(index).show();
      return false;
    });
  }
});
